import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { Box, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import styles from './HomePage.module.css';
import CatalogItem from '../../components/CatalogItem/CatalogItem';
import ProductGrid from '../../components/ProductGrid/ProductGrid';
import Filter from '../../components/Filter/Filter';
import { useParams } from 'react-router-dom';

function Homepage() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [manufacturersList, setManufacturersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [payload, setPayload] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  // useEffect(() => {}, [params.categoryId]);

  useEffect(() => {
    // setLoading(true);
    // axios
    //   .post('http://213.108.170.47:8000/api/data/', payload)
    //   .then((response) => {
    //     setData(response.data);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //   })
    //   .finally(() => setLoading(false));

    const newPayload = { pk: params.categoryId };

    setPayload(newPayload);
    onFilter(newPayload);
  }, [params.categoryId]);

  function onFilter(payload) {
    setLoading(true);
    axios
      .post('https://back.fullhard.ru/api/data/', payload)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!data) return;
    const manufacturerList = new Set(data.map((e) => e.manufacturer));
    let test = Array.from(manufacturerList);
    test.unshift('');
    setManufacturersList(test);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ProductGrid loading={loading} data={data} error={error} />
        <Filter
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          payload={payload}
          setPayload={setPayload}
          manufacturersList={manufacturersList}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          onFilter={onFilter}
        />
      </div>
    </div>
  );
}

function FormRow({ data }) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap:
          'wrap' /* Позволяет элементам переноситься на следующую строку */,
        justifyContent: 'center' /* Выравнивание по горизонтали */,
        alignItems: 'center' /* Выравнивание по вертикали */,
      }}>
      {data?.map((item) => (
        <div
          key={item.id}
          style={{
            border: '1px solid #ccc',
            marginTop: '70px',
            marginLeft: '60px',
            borderRadius: '10px',
            overflow: 'hidden',
            width: '350px' /* Ширина плитки */,
            height: '250px' /* Высота плитки */,
            margin: '2px' /* Отступ между плитками */,
            transition: 'transform 0.3s ease-in-out',
            cursor: 'pointer',
            ':hover': { transform: 'scale(1.05)' },
          }}>
          <img
            src={`https://back.fullhard.ru${item.photo}`}
            alt={item.name}
            style={{
              width: '100%',
              height: '150px',
              objectFit: 'cover',
              borderRadius: '10px 10px 0 0',
            }}
          />
          <div style={{ padding: '10px' }}>
            <h2 style={{ margin: '0', fontSize: '16px' }}>{item.name}</h2>
            <p style={{ margin: '5px 0' }}>Цена: {item.price} ₽</p>
            <p style={{ margin: '5px 0' }}>
              Производитель: {item.manufacturer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
export default Homepage;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
