import CatalogItem from '../CatalogItem/CatalogItem';
import styles from './ProductGrid.module.css';

const ProductGrid = ({ loading, data, error }) => {
  if (loading) {
    return <h1 className={styles.loading}>Loading...</h1>;
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (data.length === 0) {
    return <h1 className={styles.loading}>Пусто</h1>;
  }
  return (
    <div className={styles.containerGrid}>
      {data.map((item) => (
        <CatalogItem item={item} />
      ))}
    </div>
  );
};

export default ProductGrid;
