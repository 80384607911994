import { Link, useNavigate } from 'react-router-dom';
import styles from './RegisterPage.module.css';
import { useContext } from 'react';
import { AccountContext } from '../../App';
import axios from 'axios';

const RegisterPage = () => {
  const { setInAccount } = useContext(AccountContext);
  const navigate = useNavigate();
  function onRegister(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      firstname: formData.get('firstname'),
      lastname: formData.get('lastname'),
      email: formData.get('email'),
      password: formData.get('password'),
      password_confirm: formData.get('password_confirm'),
      role: 'MEMBER',
    };

    axios
      .post('http://213.108.170.47:8000/api/v1/registration/', payload)
      .then((data) => {
        console.log(data.data, '!!!!');
        localStorage.setItem('access', data.data.access);
        localStorage.setItem('refresh', data.data.refresh);
        setInAccount(true);
        navigate('/');
      })
      .catch((err) => {
        console.error(err, '((((');
      });
  }
  return (
    <div className={styles.wrapper}>
      <form className={styles.container} onSubmit={onRegister}>
        <h1 className={styles.heading}>Регистрация</h1>
        <input
          name="firstname"
          className={styles.input}
          placeholder="Имя"
          type=""
        />
        <input name="lastname" className={styles.input} placeholder="Фамилия" />
        <input
          name="email"
          className={styles.input}
          placeholder="Email"
          type="email"
        />
        <input
          name="password"
          className={styles.input}
          placeholder="Пароль"
          type="password"
        />
        <input
          className={styles.input}
          placeholder="Повторите пароль"
          type="password"
          name="password_confirm"
        />
        <button className={styles.button} type="submit">
          Зарегестрироваться
        </button>
        <p>
          Уже есть аккаунт? <Link to="/login">Войти</Link>
        </p>
      </form>
    </div>
  );
};

export default RegisterPage;
