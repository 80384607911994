import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>О нас</h1>
      <p className="about-description">
        Мы рады приветствовать вас в интернет-магазине Nethub - вашем надежном
        партнере в мире сетевого оборудования!
      </p>
      <p className="about-description">
        В Nethub мы предлагаем широкий выбор сетевого оборудования по выгодным
        ценам. Наш ассортимент включает в себя маршрутизаторы, коммутаторы,
        точки доступа Wi-Fi, сетевые кабели и многое другое от ведущих
        производителей.
      </p>
      <p className="about-description">
        Мы стремимся обеспечить наших клиентов качественными продуктами и
        высоким уровнем обслуживания. У нас вы найдете все необходимое для
        создания и поддержания надежной сетевой инфраструктуры для дома, офиса
        или предприятия.
      </p>
      <p className="about-description">
        Мы гордимся тем, что можем предложить вам широкий выбор товаров, быструю
        доставку и профессиональную консультацию наших специалистов.
      </p>
      <p className="about-description">
        Если у вас возникли вопросы или вам нужна помощь с выбором оборудования,
        не стесняйтесь обращаться к нам на почту Nethub@nethub.ru. Наша команда
        с удовольствием поможет вам!
      </p>
      <p className="about-description">С уважением, команда Nethub</p>
    </div>
  );
};

export default AboutPage;
