import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ProductPage.module.css';
import { BasketContext } from '../../App';

const ProductPage = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [payload, setPayload] = useState({});
  const { setBasket } = useContext(BasketContext);

  useEffect(() => {
    axios
      .post('https://back.fullhard.ru/api/data/', {})
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const currentProduct = data?.find((el) => el.id == params.id) || {};
  console.log(currentProduct);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerCont}>
        <h1>{currentProduct.category}</h1>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setBasket((prev) =>
              prev.find((e) => e.id === currentProduct.id)
                ? prev
                : [...prev, currentProduct]
            );
          }}
          className={styles.basketButton}>
          Добавить в корзину
        </button>
      </div>
      <h2>{currentProduct.name}</h2>
      <img
        src={`https://back.fullhard.ru${currentProduct.photo}`}
        alt={currentProduct.name}
        style={{
          maxWidth: '50%',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          borderRadius: '10px 10px 0 0',
        }}
      />
      <p>Производитель - {currentProduct.manufacturer}</p>
      <h4>Цена - {currentProduct.price}</h4>

      <h2>Описание</h2>
      <p>{currentProduct.description}</p>

      <h2>Атрибуты</h2>

      <table className={styles.iksweb}>
        <tbody>
          <tr>
            <th>Атрибут</th>
            <th>Значение</th>
          </tr>
          {currentProduct?.attributes?.map((attr, i) => (
            <tr key={i}>
              <td>{attr.name}</td>
              <td>{attr.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductPage;
