import { useContext, useEffect, useState } from 'react';
import styles from './Busketpage.module.css';
import { BasketContext } from '../../App';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';

const Busketpage = () => {
  const { basket, setBasket, setModal } = useContext(BasketContext);
  const [count, setCount] = useState(basket.map((e) => 1));

  useEffect(() => {
    setCount(basket.map((e) => 1));
  }, [basket]);

  function onDelete(id) {
    setBasket((prev) => prev.filter((e) => e.id !== id));
  }

  function onSendOrder(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = basket.map((el, i) => {
      return {
        product_id: el.id,
        quantity: count[i],
        name: formData.get('name'),
        lastname: formData.get('lastname'),
        address: formData.get('address'),
        payment_method: formData.get('pay'),
      };
    });

    let promises = payload.map(
      (pld) =>
        new Promise((resolve) => {
          axios
            .post('https://back.fullhard.ru/api/v1/UpdateStock', pld, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
              },
            })
            .then((data) => {
              resolve(data.data);
            })
            .catch((err) => {
              console.error(err, '((((');
            });
        })
    );
    Promise.all(promises);

    setBasket([]);
    setModal(true);
  }

  function onMinusCounter(i) {
    setCount((prev) => {
      let copy = Array.from(prev);
      if (copy[i] > 1) {
        copy[i] = copy[i] - 1;
      }
      return copy;
    });
  }
  function onPlusCounter(i) {
    setCount((prev) => {
      let copy = Array.from(prev);
      copy[i] = copy[i] + 1;
      return copy;
    });
  }
  let formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });

  const totalCount =
    count?.reduce(
      (acc, value, idx) => acc + value * basket[idx]?.price,
      1500
    ) || 1500;
  const formattedTotal = formatter.format(totalCount);
  const formattedDelivery = formatter.format(1500);

  if (!localStorage.getItem('access')) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2>Корзина</h2>
        <div className={styles.contentContainer}>
          <div className={styles.leftPart}>
            <p>Товар</p>
            <p />
            <p className={styles.leftPrice}>Итого</p>

            {basket.map((row, i) => {
              const totalSum = count[i] * row?.price;
              const formattedSum = formatter.format(totalSum);
              const formattedPrice = formatter.format(row.price);
              return (
                <>
                  <div className={styles.divider} />
                  <div className={styles.leftRow}>
                    <img
                      src={`https://back.fullhard.ru${row.photo}`}
                      alt={row.name}
                      style={{
                        maxWidth: '100%',
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0 0',
                        alignSelf: 'center',
                      }}
                    />
                    <div className={styles.leftMiddleGrid}>
                      <p className={styles.leftName}>{row.name}</p>
                      <p className={styles.leftMiddlePrice}>{formattedPrice}</p>
                      <div className={styles.leftMiddleCounterCont}>
                        <div className={styles.leftMiddleCounter}>
                          <span
                            className={styles.operator}
                            onClick={() => onMinusCounter(i)}>
                            -
                          </span>
                          <span>{count[i]}</span>
                          <span
                            className={styles.operator}
                            onClick={() => onPlusCounter(i)}>
                            +
                          </span>
                        </div>
                        <div
                          onClick={() => onDelete(row.id)}
                          className={styles.delete}>
                          Удалить из коорзины
                        </div>
                      </div>
                    </div>
                    <p className={styles.leftPrice}>{formattedSum}</p>
                  </div>
                </>
              );
            })}
          </div>
          <div className={styles.rightPart}>
            <p className={styles.rightTitle}>Сумма заказов</p>
            <div className={styles.divider} />
            <p>Доставка</p>
            <p className={styles.rightPrice}>{formattedDelivery}</p>
            <div className={styles.divider} />
            <p>Итого</p>
            <p className={styles.rightPrice}>{formattedTotal}</p>

            <form className={styles.formContainer} onSubmit={onSendOrder}>
              <h1>Оформление заказа</h1>
              <input
                name="name"
                className={styles.formInput}
                placeholder="Имя"
              />
              <input
                name="lastname"
                className={styles.formInput}
                placeholder="Фамилия"
              />
              <input
                name="address"
                className={styles.formInput}
                placeholder="Адрес"
              />
              <select name="pay" className={styles.formSelect}>
                <option value="CARD">Картой</option>
                <option value="ON_DELIVERY">При доставке</option>
              </select>
              <button className={styles.sendButton} type="submit">
                Разместить заказ
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Busketpage;
