import './App.css';
import { Routes, Route, NavLink, Link, useParams } from 'react-router-dom';
import Aboutpage from './pages/Aboutpage/Aboutpage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import Busketpage from './pages/BusketPage/Busketpage';
import CabinetPage from './pages/CabinetPage/CabinetPage';
import { Notfoundpage } from './pages/Notfoundpage';
import { Routers } from './pages/Routers';
import { Switches } from './pages/Switches';
import { Acsessuares } from './pages/Acsessuares';
import Homepage from './pages/HomePage/Homepage'; // Импортируем компонент Homepage
import styles from './App.module.css';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import { createContext, useEffect, useState } from 'react';
import ProductPage from './pages/ProductPage/ProductPage';
import CategoryCatalog from './pages/CategoryCatalog/CategoryCatalog';
import axios from 'axios';
import Modal from './components/Modal/Modal';

export const AccountContext = createContext({});
export const BasketContext = createContext({});

function App() {
  const [inAccount, setInAccount] = useState(!!localStorage.getItem('access'));
  const [menuList, setMenuList] = useState([]);
  const [basket, setBasket] = useState([]);
  const [modal, setModal] = useState(false);

  function onLogout() {
    localStorage.clear();
    setInAccount(false);
  }

  useEffect(() => {
    axios
      .get('https://back.fullhard.ru/api/v1/category')
      .then((data) => setMenuList(data.data))
      .catch((e) => console.error(e));
  }, []);

  return (
    <AccountContext.Provider value={{ inAccount, setInAccount }}>
      <BasketContext.Provider value={{ basket, setBasket, setModal }}>
        <Modal active={modal} setActive={setModal}>
          <h1 className={styles.modalTitle}>Заказ отправлен!</h1>
        </Modal>
        <header className={styles.header}>
          <Link to="/" className="logo">
            <h1>NetHub</h1>
          </Link>
          <div className={styles.menu}>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : ''
              }
              to="/">
              Главная
            </NavLink>
            {menuList.map((link) => (
              <NavLink
                key={link.pk}
                className={({ isActive }) =>
                  isActive ? styles.activeNavLink : ''
                }
                to={`/category/${link.pk}`}>
                {link.name}
              </NavLink>
            ))}

            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : ''
              }
              to="/about">
              О нас
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : ''
              }
              to="/contacts">
              Контакты
            </NavLink>
          </div>
          <nav className={styles.divider} />
          <div className={styles.menu}>
            {inAccount && (
              <div className={styles.basket}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.activeNavLink : ''
                  }
                  to="/busket">
                  Корзина
                </NavLink>
                <span className={styles.basketCounter}>{basket.length}</span>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.activeNavLink : ''
                  }
                  to="/cabinet">
                  Аккаунт
                </NavLink>
              </div>
            )}
            {inAccount ? (
              <button className={styles.logoutButton} onClick={onLogout}>
                Выйти
              </button>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNavLink : ''
                }
                to="/login">
                Вход
              </NavLink>
            )}
          </div>
        </header>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/switch" element={<Switches />} />
              <Route path="/routers" element={<Routers />} />
              <Route path="/acsessuares" element={<Acsessuares />} />
              <Route path="/about" element={<Aboutpage />} />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/product/:id" element={<ProductPage />} />
              <Route
                path="/category/:categoryId"
                element={<CategoryCatalog />}
              />
              <Route path="/busket" element={<Busketpage />} />
              <Route path="/cabinet" element={<CabinetPage />} />
              <Route path="*" element={<Notfoundpage />} />
            </Routes>
          </div>
        </div>
      </BasketContext.Provider>
    </AccountContext.Provider>
  );
}

export default App;
