import React from 'react';
import './ContactsPage.css';

const ContactsPage = () => {
  return (
    <div className="contact-info">
      <h2>КОНТАКТЫ</h2>

      <div className="contact-section">
        <h3>Адрес</h3>
        <p>г. Саратов, ул. Политехническая, 77</p>
      </div>

      <div className="contact-section">
        <h3>Режим работы</h3>
        <p>
          Понедельник – Пятница
          <br />
          09:00 – 18:00 (МСК)
        </p>
      </div>

      <div className="contact-section">
        <h3>Свяжитесь с нами</h3>

        <div className="department">
          <h4>Отдел продаж</h4>
          <p>+7 (000) 000 00 00</p>
          <p>sales@nethub.ru</p>
          <p>
            Подбор технического решения под задачу, консультации по имеющимся
            функциям системы, ведение проектов.
          </p>
        </div>

        <div className="department">
          <h4>Отдел маркетинга</h4>
          <p>+7 (000) 000 00 00</p>
          <p>marketing@nethub.ru</p>
          <p>
            Взаимодействие со СМИ, участие в мероприятиях, запрос материалов для
            размещения на партнерских ресурсах.
          </p>
        </div>

        <div className="department">
          <h4>Техническая поддержка</h4>
          <p>+7 (000) 000 00 00</p>
          <p>support@nethub.ru</p>
          <p>Консультации по настройке и решению проблем с оборудованием.</p>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
