import { useEffect } from 'react';
import styles from './Filter.module.css';

const Filter = ({
  selectValue,
  setSelectValue,
  payload,
  setPayload,
  manufacturersList,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  initialPayload = {},
  onFilter,
}) => {
  function onReset() {
    setPayload(initialPayload);
    setSelectValue('');
    setMinPrice('');
    setMaxPrice('');
    onFilter(initialPayload);
  }

  useEffect(() => {
    const copy = { ...payload };
    if (selectValue) {
      copy.manufacturer = selectValue;
    } else {
      delete copy.manufacturer;
    }
    if (minPrice) {
      copy.min_price = minPrice;
    } else {
      delete copy.min_price;
    }
    if (maxPrice) {
      copy.max_price = maxPrice;
    } else {
      delete copy.max_price;
    }

    setPayload(copy);
  }, [selectValue, minPrice, maxPrice]);

  return (
    <div className={styles.filterContainer}>
      <h2 className={styles.filterHeading}>Фильтры</h2>
      <div className={styles.filters}>
        <h4>Производитель</h4>
        <select
          value={selectValue}
          className={styles.select}
          onChange={(e) => {
            setSelectValue(e.target.value);
            setPayload((prev) => ({
              ...prev,
              manufacturer: e.target.value,
            }));
          }}>
          {manufacturersList.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <h4>Минимальаня Цена</h4>
        <input
          value={minPrice}
          onChange={(e) => {
            setMinPrice(e.target.value);
          }}
          className={styles.priceInput}
          placeholder="Минимальаня Цена"
          type="number"
        />
        <h4>Максимальная Цена</h4>
        <input
          value={maxPrice}
          onChange={(e) => {
            setMaxPrice(e.target.value);
          }}
          className={styles.priceInput}
          placeholder="Максимальная Цена"
          type="number"
        />
      </div>
      <button onClick={() => onFilter(payload)} className={styles.resetButton}>
        Фильтровать
      </button>
      <button onClick={onReset} className={styles.resetButton}>
        Сбросить
      </button>
    </div>
  );
};

export default Filter;
