import styles from './Modal.module.css';

const Modal = ({ active, setActive, children }) => {
  return (
    <div
      className={[styles.wrapper, active ? styles.active : ''].join(' ')}
      onClick={() => setActive(false)}>
      <div
        className={[styles.container, active ? styles.active : ''].join(' ')}
        onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
