import { useParams } from 'react-router-dom';
import styles from './CategoryCatalog.module.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ProductGrid from '../../components/ProductGrid/ProductGrid';
import Filter from '../../components/Filter/Filter';

const CategoryCatalog = () => {
  const params = useParams();
  const initialPayload = { pk: params.categoryId };
  const [data, setData] = useState(null);
  const [manufacturersList, setManufacturersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [payload, setPayload] = useState(initialPayload);
  const [selectValue, setSelectValue] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  useEffect(() => {
    const newPayload = { pk: params.categoryId };

    setPayload(newPayload);
    onFilter(newPayload);
  }, [params.categoryId]);

  function onFilter(payload) {
    setLoading(true);
    axios
      .post('https://back.fullhard.ru/api/data/', payload)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!data) return;
    const manufacturerList = new Set(data.map((e) => e.manufacturer));
    let test = Array.from(manufacturerList);
    test.unshift('');
    setManufacturersList(test);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ProductGrid loading={loading} data={data} error={error} />
        <Filter
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          payload={payload}
          setPayload={setPayload}
          manufacturersList={manufacturersList}
          initialPayload={initialPayload}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          onFilter={onFilter}
        />
      </div>
    </div>
  );
};

export default CategoryCatalog;
