import { Link } from 'react-router-dom';
import styles from './CatalogItem.module.css';
import { useContext } from 'react';
import { BasketContext } from '../../App';

const CatalogItem = ({ item }) => {
  const { setBasket } = useContext(BasketContext);
  let formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });

  const formattedPrice = formatter.format(item.price);

  return (
    <Link key={item.id} to={`/product/${item.id}`}>
      <div key={item.id} className={styles.container}>
        <img
          src={`https://back.fullhard.ru${item.photo}`}
          alt={item.name}
          style={{
            width: '100%',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '10px ',
          }}
        />
        <div style={{ paddingTop: '10px' }}>
          <h2 style={{ margin: '0', fontSize: '16px' }}>{item.name}</h2>
          <p style={{ margin: '5px 0' }}>Цена: {formattedPrice}</p>
          <p style={{ margin: '5px 0' }}>Производитель: {item.manufacturer}</p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setBasket((prev) =>
              prev.find((e) => e.id === item.id) ? prev : [...prev, item]
            );
          }}
          className={styles.basketButton}>
          Добавить в корзину
        </button>
      </div>
    </Link>
  );
};

export default CatalogItem;
