import { Link, useNavigate } from 'react-router-dom';
import styles from './LoginPage.module.css';
import axios from 'axios';
import { useContext } from 'react';
import { AccountContext } from '../../App';

const LoginPage = () => {
  const { setInAccount } = useContext(AccountContext);
  const navigate = useNavigate();
  function onLogin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      username: formData.get('login'),
      password: formData.get('password'),
    };

    axios
      .post('https://back.fullhard.ru/api/token/', payload)
      .then((data) => {
        localStorage.setItem('access', data.data.access);
        localStorage.setItem('refresh', data.data.refresh);
        setInAccount(true);
        navigate('/');
      })
      .catch((err) => {
        console.error(err, '((((');
      });
  }

  return (
    <div className={styles.wrapper}>
      <form className={styles.container} onSubmit={onLogin}>
        <h1 className={styles.heading}>Вход</h1>
        <input
          name="login"
          className={styles.input}
          placeholder="Логин"
          type=""
        />
        <input
          name="password"
          className={styles.input}
          placeholder="Пароль"
          type="password"
        />
        <button className={styles.button} type="submit">
          Войти
        </button>
        <p>
          Нет аккаунта? <Link to="/register"> Зарегестрироваться </Link>
        </p>
      </form>
    </div>
  );
};

export default LoginPage;
